import React from 'react';
import AnimatedText from "../AnimatedText";
import bg1 from "../../assets/background/bg1.png"
import bg1_lg from "../../assets/background/bg1_lg.png"
import bg2 from "../../assets/background/bg2.svg"
import bg2_lg from "../../assets/background/bg2_lg.svg"
import love1 from "../../assets/loves/love1.jpg"
import bg1_sm from "../../assets/background/bg1_sm.png"
import bg2_sm from "../../assets/background/bg2_sm.svg"
import {useScroll, useTransform, motion} from "framer-motion";
import {useWindowSize} from 'react-use';
import clsx from "clsx";
import {useSearchParams} from "react-router-dom";

const InvitationCard = () => {
    const {scrollY} = useScroll();
    const {width, height} = useWindowSize();
    const ratio = width / height
    const windowHeight = window.innerHeight;
    const scale = useTransform(scrollY, [0, windowHeight], [1, width > 600 ? 0.5 : 0.8]);
    const opacity = useTransform(scrollY, [0, windowHeight / 2], [1, 0]);
    const opacityImg = useTransform(scrollY, [windowHeight / 2, windowHeight], [0, 1]);
    const [searchParams] = useSearchParams();
    const name = searchParams.get("name");
    return (
        <div className="h-[250vh] flex items-center relative flex-col">
            <div
                className="w-full h-screen sticky flex flex-col top-0 justify-center items-center overflow-hidden">
                <motion.div style={{opacity: opacity}} className="flex flex-col items-center">
                    <div className="z-10 relative">
                        <AnimatedText value="Thanh Tùng & Nguyễn Lệ"
                                      className="text-[22px] min-[416px]:text-2xl min-[767px]:text-3xl !leading-tight min-[991px]:text-5xl font-light"/>
                    </div>

                    <div className="flex items-center z-20 relative mt-[2vw]">
                        <div className="w-[3vw] h-[1px] bg-[#f1c6b5] mr-3"/>
                        <div className="text-sm min-[416px]:text-base">
                            Ngày 10 tháng 11 năm 2024
                        </div>
                        <div className="w-[3vw] h-[1px] bg-[#f1c6b5] ml-3"/>
                    </div>
                    <motion.div
                        initial={{x: 100, opacity: 0}}
                        whileInView={{x: 0, opacity: 1}}
                        transition={{duration: 2}}
                        className="mt-8 text-xl italic min-[767px]:text-xl">
                        <span>Kính mời:</span>&nbsp;
                        <strong className="text-xl min-[767px]:2text-xl">{name}</strong></motion.div>

                </motion.div>
                <motion.img src={love1} style={{opacity: opacityImg}} alt=""
                            className={clsx("absolute object-cover", ratio > 1 ? "w-[40vw] h-[50vh]" : ratio > 0.7 ? "w-[40vw] h-[50vh]" : "w-[60vw] h-[40vh]")}/>
                <motion.img src={ratio > 1 ? bg1 : ratio > 0.7 ? bg1_lg : bg1_sm} alt=""
                            style={{
                                scale: scale, // Liên kết scale với giá trị từ useTransform
                            }}
                            className={clsx("h-auto object-cover absolute max-w-none top-auto  left-auto right-auto bottom-auto", ratio > 1 ? "w-[161vw]" : "w-[107vw]")}/>
                <motion.img src={ratio > 1 ? bg2 : ratio > 0.7 ? bg2_lg : bg2_sm} alt=""
                            style={{
                                scale: scale, // Liên kết scale với giá trị từ useTransform
                            }}
                            className={clsx("h-auto object-cover absolute max-w-none top-auto  left-auto right-auto bottom-auto", ratio > 1 ? "w-[161vw]" : "w-[107vw]")}/>
            </div>


        </div>
    )
}

export default InvitationCard
