import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import clsx from "clsx";
import {useCopyToClipboard} from "react-use";


const Admin = () => {
    const {register, handleSubmit, reset} = useForm()
    const [link, setLink] = useState("");
    const [state, copyToClipboard] = useCopyToClipboard();
    const onSubmit = (data) => {
        setLink(`${window.location.origin}?name=${encodeURIComponent(data.name)}`);
    }

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col max-w-xl px-6 w-full items-center mt-2">
                <div className="uppercase mb-20 text-xl text-[#a97143]">Tạo link khách mời</div>
                <div className={clsx("w-full", !link && "invisible")}>
                    <div
                        className="inline-flex mb-6 border-[#a97143] border justify-between w-full items-center text-base  text-gray-500 rounded-lg bg-gray-100 hover:text-gray-900 hover:bg-gray-100">
                        <div style={{width: "calc(100% - 60px)"}} className="truncate p-4 ">{link}</div>
                        <button
                            onClick={()=>copyToClipboard(link)}
                            className=" h-[56px] px-4 text-white whitespace-nowrap bg-[#a97143] hover:bg-amber-700 focus:outline-none rounded-r-lg">Copy
                        </button>
                    </div>
                </div>
                <input{...register("name", {required: true})}
                      maxLength={20}
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      placeholder="Tên khách mời" required/>
                <button type="submit"
                        className="mt-4 w-full text-white whitespace-nowrap bg-[#a97143] hover:bg-amber-700 focus:outline-none focus:ring-4 focus:ring-amber-900 font-medium rounded-full text-sm px-5 py-2.5 text-center">
                    Tạo link
                </button>
            </form>
        </div>
    )
}

export default Admin