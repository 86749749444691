import React from 'react';
import {Gallery,Item } from "react-photoswipe-gallery";
import clsx from "clsx";
import arrow_right from "../../assets/icons/arrow_right.svg"


const ImageView = ({images}) => {
    return (
        <Gallery>
            <div>
                {images.map((image, index) => (
                    <Item
                        key={image.url}
                        original={image.url}
                        width={image.width}
                        height={image.height}
                    >
                        {({ ref, open }) => (
                            <>
                                <img ref={ref} onClick={open} className={clsx(index > 0 && "hidden","w-full cursor-pointer")} src={image.url}/>
                                <div onClick={open} className={clsx(index > 0 && "hidden","mt-4 text-[#a97143] cursor-pointer flex item-center flex-row justify-end")}>
                                    Xem ảnh
                                    <img src={arrow_right} width="24" className="ml-1"/>
                                </div>
                            </>
                        )}
                    </Item>
                ))}
            </div>
        </Gallery>
    )
}

export default ImageView