import {AnimatePresence, motion} from "framer-motion";
import {createPortal} from "react-dom";
import cancel_icon from "../../assets/icons/cancel_black.svg"
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {addRegister, deleteRegister, listenRegister} from "../../firebase";
import love from "../../assets/icons/love.png"
import hearts from "../../assets/icons/hearts.png"
import flower7 from "../../assets/background/flower7.svg"
import flower6 from "../../assets/background/flower6.svg"
import removeIcon from "../../assets/icons/remove.svg"
import clsx from "clsx";

const Content = () => {
    const {register, handleSubmit, reset} = useForm()
    const [list, setList] = useState([]);
    const listId = useMemo(() => {
        const id = localStorage.getItem("register");
        if (id) {
            return JSON.parse(id);
        }
        return []
    }, [list])
    useEffect(() => {
        listenRegister((data) => {
            setList(data)
        })
    }, [])
    const onSubmit = (data) => {
        addRegister(data).then((id) => {
            localStorage.setItem("register", JSON.stringify([...listId,id]));
        })
        reset();
    }
    const removeRegister = (id) =>()=> {
        deleteRegister(id).then()
    }
    return (
        <div className="w-full h-full flex p-4 items-center flex-col relative">
            <img src={flower7} className="absolute top-0 right-0 min-[600px]:w-[26vw] w-[40vw] rotate-180 opacity-40"/>
            <img src={flower6} className="absolute bottom-0 left-0 min-[600px]:w-[39vw] w-[50vw] opacity-40"/>
            <div className="w-full max-w-xl mt-4 relative">
                <div className="text-xl min-[780px]:text-2xl text-[#a97143] uppercase text-center mb-2">Danh sách tham
                    gia
                </div>
                <div style={{height: "calc(100vh - 190px)", overflowY: "auto"}}
                     className="divide-y divide-gray-200 px-2">
                    {list.map((item) => (
                        <div key={item.id} className="flex items-center space-x-4  py-2">
                            <div className="">
                                <img className="w-6 h-6 rounded-full"
                                     src={love} alt=""/>
                            </div>
                            <div className=" min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                    {item.name}
                                </p>
                            </div>
                            <div className="flex-1"/>
                            <div
                                className="inline-flex  items-center text-sm text-gray-500 truncate ">
                                {item?.time?.toDate()?.toLocaleString()}
                            </div>
                            <button onClick={removeRegister(item.id)}
                                    className={clsx(!listId.includes(item.id)&&"hidden")}><img src={removeIcon} width="18"/></button>
                        </div>
                    ))}
                </div>
                <div className="flex pt-4 items-center justify-between">
                    <div>(*) Đăng ký tham gia đi xe từ Hà Nội</div>
                    <div className=" flex space-x-2">
                        <img src={hearts} className="w-6 h-6"/>
                        <div className="text-[#a97143] font-bold text-xl">{list.length}</div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="flex items-center mt-2">
                    <input{...register("name", {required: true, maxLength: 20})}
                          maxLength={20}
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          placeholder="Tên của bạn" required/>
                    <button type="submit"
                            className="ml-4 text-white whitespace-nowrap bg-[#a97143] hover:bg-amber-700 focus:outline-none focus:ring-4 focus:ring-amber-900 font-medium rounded-full text-sm px-5 py-2.5 text-center">
                        Đăng ký
                    </button>
                </form>

            </div>
        </div>
    )
}
const DialogRegister = ({open, onClose}) => {
    useEffect(() => {
        if (open) {
            document.body.style.paddingRight = "19px";
            document.documentElement.style.overflow = "hidden"
        } else {
            document.body.style.paddingRight = "0";
            document.documentElement.style.overflow = "auto"
        }
    }, [open])
    return (
        createPortal(<AnimatePresence>
            {open && (
                <motion.div
                    className="fixed top-0 left-0 w-screen h-screen z-[1000] bg-[#f1c6b5]"
                    initial={{opacity: 1, y: "-100%"}}
                    animate={{opacity: 1, y: "0%"}}
                    exit={{opacity: 1, y: "-100%"}}
                    transition={{duration: 0.5}}
                >
                    <div className="w-full h-full">
                        <Content/>
                    </div>
                    <button onClick={onClose} className="fixed right-2 top-2">
                        <img src={cancel_icon} className="w-8 h-8"/>
                    </button>
                </motion.div>
            )}
        </AnimatePresence>, document.body)
    )
}
export default DialogRegister
