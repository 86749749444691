import AnimatedText from "../AnimatedText";
import {useInView, motion} from "framer-motion";
import {useEffect, useRef} from "react";
import flower2 from "../../assets/background/flower2.svg"
import flower3 from "../../assets/background/flower3.svg"
import flower4 from "../../assets/background/flower4.svg"
import flower5 from "../../assets/background/flower5.svg"
import love7 from "../../assets/loves/love7.jpg"
import love8 from "../../assets/loves/love8.jpg"
import love9 from "../../assets/loves/love9.jpg"
import love10 from "../../assets/loves/love10.jpg"
import ImageView from "../ImageView";

const LoveLine = ({changeBackground}) => {
    const ref = useRef(null)
    const isInView = useInView(ref);
    useEffect(() => {
        changeBackground(isInView);
    }, [isInView])
    return (
        <div className="flex flex-col items-center z-10 relative pb-4 w-full overflow-hidden" ref={ref}>
            <AnimatedText value="LoveLine" className="text-2xl min-[767px]:text-5xl min-[400px]:text-4xl font-light"/>
            <img src={flower2} alt="" className="opacity-20 absolute w-[51vw] right-[-14%] rotate-[-18deg]"/>
            <div className="min-[767px]:w-[80vw] min-[767px]:px:0 w-full px-8">
                <div className="min-[767px]:flex-row flex-col flex mt-20 w-full">
                    <div className="flex flex-1 flex-col relative h-[25vw]">
                        <img src={flower3}
                             className="w-[40vw] absolute opacity-85 rotate-[166deg] top-[-10vw] left-[-6vw]"/>
                        <motion.div whileInView={{x: 0, y: 0, opacity: 1}}
                                    initial={{x: -200, y: -200, opacity: 0}}
                                    transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                                    viewport={{once: true}}
                                    className="min-[767px]:w-[30vw] w-full relative">
                            <ImageView images={
                                [{url: love7, width: 1000, height: 666}, {
                                    url: love8,
                                    width: 600,
                                    height: 900
                                }]}/>
                        </motion.div>
                    </div>
                    <div className="min-[767px]:flex w-[120px] hidden flex-col items-center mt-12">
                        <div className="bg-[#f1c6b5] absolute rounded-full w-[1.35vw] h-[1.35vw]"/>
                        <div className="h-full  bg-[#f1c6b5] w-[1px] mt-10"/>
                    </div>
                    <div className="flex-1 min-[767px]:mt-20 mt-10">
                        <motion.div
                            initial={{x: 100, opacity: 0}}
                            whileInView={{x: 0, opacity: 1}}
                            transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                            viewport={{once: true}}
                            className="font-bold min-[767px]:text-[2.4vw] text-2xl">
                            First time we met
                        </motion.div>
                        <motion.div
                            initial={{y: 100, opacity: 0}}
                            whileInView={{y: 0, opacity: 1}}
                            transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                            viewport={{once: true}}
                            className="min-[767px]:max-w-96 mt-2">
                            Cras at odio non nisl congue pretium. Nullam mattis justo vel velit feugiat imperdiet non
                            vel
                            arcu. Sed sed leo et ipsum interdum mollis.
                        </motion.div>
                    </div>
                </div>
                <div className="min-[767px]:flex-row flex-col flex mt-10 w-full">
                    <div className="flex flex-1 flex-col items-end  mt-10 min-[767px]:order-1 order-3">
                        <div className="">
                            <motion.div
                                whileInView={{x: 0, opacity: 1}}
                                initial={{x: -100, opacity: 0}}
                                viewport={{once: true}}
                                transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                                className="font-bold min-[767px]:text-[2.4vw] text-2xl">
                                Serendipity
                            </motion.div>
                            <motion.div
                                whileInView={{y: 0, opacity: 1}}
                                initial={{y: 100, opacity: 0}}
                                viewport={{once: true}}
                                transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                                className="min-[767px]:max-w-96 mt-2 ">
                                Cras at odio non nisl congue pretium. Nullam mattis justo vel velit feugiat imperdiet
                                non
                                vel
                                arcu. Sed sed leo et ipsum interdum mollis.
                            </motion.div>
                        </div>
                    </div>
                    <div className="w-[120px] min-[767px]:flex hidden flex-col order-2 items-center min-[767px]:mt-6">
                        <div className="bg-[#f1c6b5] absolute rounded-full w-[1.35vw] h-[1.35vw]"/>
                        <div className="h-full bg-[#f1c6b5] w-[1px] mt-10 "/>
                    </div>
                    <div className="flex-1 relative min-[767px]:order-3 order-1">
                        <img src={flower5} className="w-[35vw]  left-[2vw] absolute top-[-10vw]"/>
                        <motion.div
                            whileInView={{x: 0, opacity: 1}}
                            initial={{x: 200, opacity: 0}}
                            viewport={{once: true}}
                            transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                            className="min-[767px]:w-[27vw] w-full min-[767px]:mb-20 relative">
                            <img src={love9} className="w-full"/>
                        </motion.div>
                    </div>
                </div>
                <div className="min-[767px]:flex-row flex-col flex  w-full">
                    <div className="flex flex-1 justify-end relative">
                        <img src={flower4} className="left-[-10vw] top-[-16vw] absolute rotate-[166deg]"/>
                        <motion.div
                            whileInView={{x: 0, opacity: 1}}
                            initial={{x: -200, opacity: 0}}
                            transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                            viewport={{once: true}}
                            className="min-[767px]:w-[30vw] w-full relative">
                            <img src={love10} className="w-full"/>
                        </motion.div>
                    </div>
                    <div className="w-[120px]  min-[767px]:flex hidden flex-col items-center mt-12">
                        <div className="bg-[#f1c6b5] absolute rounded-full w-[1.35vw] h-[1.35vw]"/>
                    </div>
                    <div className="flex-1  min-[767px]:mt-20 mt-10">
                        <motion.div
                            whileInView={{x: 0, opacity: 1}}
                            initial={{x: 100, opacity: 0}}
                            viewport={{once: true}}
                            transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                            className="font-bold min-[767px]:text-[2.4vw] text-2xl">
                            Together, always
                        </motion.div>
                        <motion.div
                            whileInView={{x: 0, opacity: 1}}
                            initial={{x: 100, opacity: 0}}
                            viewport={{once: true}}
                            transition={{duration: 1, type: "spring", ease: 'easeInOut'}}
                            className="min-[767px]:max-w-96 mt-2">
                            Cras at odio non nisl congue pretium. Nullam mattis justo vel velit feugiat imperdiet non
                            vel
                            arcu. Sed sed leo et ipsum interdum mollis.
                        </motion.div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default LoveLine
