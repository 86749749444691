import React, {useRef} from 'react';
import {motion, useInView} from 'framer-motion';
import clsx from "clsx";


const AnimatedText = ({
                          value, className, x = -100, y = 0,
                          isDelay = false, root, once = true
                      }) => {
    const text = value.split(''); // Tách chuỗi thành từng chữ cái
    const ref = useRef(null);
    const isInView = useInView(root || ref, {once});
    // Animation variants cho mỗi chữ cái
    const variants = {
        hidden: {x: x, y: y, opacity: 1}, // Bắt đầu bên trái với opacity 0
        visible: (i) => ({
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                stiffness: 100,
                delay: isDelay ? i * 0.05 : 0
            },
        }),
    };

    return (
        <div style={{display: 'flex', gap: '0.2rem'}} ref={ref}>
            {text.map((char, index) => (
                <div className="overflow-hidden ">
                    <motion.div
                        key={index}
                        custom={index}
                        variants={variants}
                        animate={isInView ? "visible" : "hidden"}
                        initial="hidden"
                        className={clsx(className,"whitespace-pre-wrap !leading-tight")}
                    >
                        {char}
                    </motion.div>
                </div>
            ))}
        </div>
    );
}

export default AnimatedText
