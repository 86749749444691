import AnimatedText from "../AnimatedText";
import flower from "../../assets/background/flower.svg"
import love3 from "../../assets/loves/love3.jpg"
import love4 from "../../assets/loves/love4.jpg"
import love5 from "../../assets/loves/love5.jpg"
import love6 from "../../assets/loves/love6.jpeg"
import play_icon from "../../assets/icons/play_circle.svg"
import {motion} from 'framer-motion';
import {useRef, useState} from "react";
import clsx from "clsx";
import DialogVideo from "../DialogVideo/DialogVideo";
import {useToggle} from "react-use";

const Item = ({src, className, x, y, root,link}) => {

    const elementRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [open, toggle] = useToggle(false)
    const handleZoomToggle = () => {

        // setTimeout(()=>{
        //     setIsZoomed(true);
        // })
    };

    return (
        <>
            <motion.div whileHover={{scale: 1.1, zIndex: 100, transition: {duration: 0.3}}}
                        ref={elementRef}
                        initial={{y: y, x: x, filter: 'grayscale(100%)'}}
                        whileInView={{y: 0, x: 0, filter: 'grayscale(0%)'}}
                        onHoverStart={() => setIsHovered(true)}
                        onHoverEnd={() => setIsHovered(false)}
                        onClick={toggle}
                        transition={{
                            duration: 1,
                            ease: 'easeInOut'
                        }}
                        className={clsx("flex justify-center overflow-hidden rounded-lg items-center cursor-pointer", className)}
                        viewport={{root: root, once: true}}>
                <motion.img
                    src={src} alt="" className="w-full h-auto"/>
                <motion.div animate={{opacity: isHovered ? 0 : 0.2}}
                            className="absolute top-0 left-0 right-0 bottom-0 bg-black"/>
                <img src={play_icon} alt="" className="absolute w-12"/>
            </motion.div>
            <DialogVideo open={open} onClose={toggle} link={link}/>
        </>
    )
}

const SpecialMoments = () => {
    const ref = useRef(null)
    return (
        <div className="flex flex-col relative items-center justify-center pb-[30%] min-[1000px]:pb-[20%] overflow-hidden">
            <img src={flower} alt="" className="absolute w-[28vw] opacity-35  -left-12 transform -scale-x-100 top-1"/>
            <AnimatedText value="Một khoảnh khắc" className="text-3xl min-[767px]:text-5xl min-[400px]:text-4xl font-light"/>
            <div ref={ref} className="w-[96vw] h-[96vw] m-auto relative min-[1000px]:w-3/5 min-[1000px]:h-[65vw]">
                <Item
                    y={100}
                    root={ref}
                    link="https://firebasestorage.googleapis.com/v0/b/tungvale-553cb.appspot.com/o/copy_1F257908-FA8B-41B3-8D74-95B914EE5F0B.MOV?alt=media&token=447962dc-4561-43c8-bf52-e8f868f993a6"
                    src={love3} alt="" className="w-[40%] z-10 absolute top-[6%] left-[31%]"/>
                <Item x={100} src={love5}
                      root={ref}
                      link="https://firebasestorage.googleapis.com/v0/b/tungvale-553cb.appspot.com/o/copy_240068C9-532F-4365-9F3D-FAB379F96B02.mov?alt=media&token=73b7f334-5a00-4d60-a9f3-f73e0efcd317"
                      alt="" className="w-[41.5%] absolute top-[36%] right-0"/>
                <Item
                    x={-100}
                    root={ref}
                    link="https://firebasestorage.googleapis.com/v0/b/tungvale-553cb.appspot.com/o/E93C6426-E197-4A1A-B8EE-ACCD5711C7BF.MOV?alt=media&token=b4e2ab6f-d980-43fa-93a9-61b3513434d5"
                    src={love4} alt="" className="w-[38%] z-10 absolute left-[4%] bottom-[8%]" />

                <Item
                    y={-100}
                    root={ref}
                    link="https://firebasestorage.googleapis.com/v0/b/tungvale-553cb.appspot.com/o/copy_BAC8952D-02BC-451D-BEFB-0D5E9E54128F.MOV?alt=media&token=33f85880-711d-4c8f-ba10-1bc523385d7b"
                    src={love6} alt="" className="w-[63%]  absolute right-[8%] bottom-[-16%]"/>
                <motion.div
                    className="text-[#a97143] absolute top-[55%] text-xs min-[1000px]:text-lg left-[-80px] min-[1000px]:left-[-15%] origin-center rotate-90">
                    KỶ NIỆM KHÔNG QUÊN
                </motion.div>
            </div>
        </div>
    )
}
export default SpecialMoments
