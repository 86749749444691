import React from 'react';
import AnimatedText from "../AnimatedText";
import img1 from "../../assets/images/an_hoi.jpg"
import img2 from "../../assets/images/xe_cuoi.jpg"
import img3 from "../../assets/images/img3.jpeg"
import clsx from "clsx";
import {motion} from "framer-motion";

export const Item = ({url, children, isReverse}) => {
    return (
        <div className={clsx("w-full flex max-[750px]:flex-col-reverse bg-[#edb9a6]", isReverse && "flex-row-reverse")}>
            <div className=" flex flex-col justify-center p-8  w-1/2 max-[750px]:w-full text-white">
                <motion.div initial={{y: 100, opacity: 0}}
                            transition={{duration: 1}}
                            viewport={{once: true}}
                            whileInView={{y: 0, opacity: 1}}>
                    {children}
                </motion.div>
            </div>
            <div className="w-1/2 max-[750px]:w-full">
                <motion.img
                    initial={{x: isReverse ? -200 : 200, opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{duration: 1}}
                    viewport={{once: true}}
                    className="w-full object-cover" src={url}/>
            </div>
        </div>
    )
}
const EventParty = ({}) => {
    return (
        <div className="w-full flex flex-col justify-center items-center mt-10">
            <AnimatedText value="Sự kiện và tiệc mừng"
                          className="text-2xl min-[767px]:text-5xl min-[400px]:text-4xl font-light"/>
            <div className=" w-[93%] max-w-[940px] m-auto mt-16">
                <Item url={img1}>
                    <div className="text-2xl font-bold mb-4">Ăn hỏi</div>
                    <p>Friday</p>
                    <p>November 15, 2021</p>
                    <p>Dinner 7:00 pm</p>
                    <p className="mt-4">WELL COTTAGE</p>
                    <p>4416 Leo Street,</p>
                    <p>Denver,80216 CO</p>
                </Item>
                <Item isReverse url={img2}>
                    <div className="text-2xl font-bold mb-4">Wellcome</div>
                    <p>Friday</p>
                    <p>November 15, 2021</p>
                    <p>Dinner 7:00 pm</p>
                    <p className="mt-4">WELL COTTAGE</p>
                    <p>4416 Leo Street,</p>
                    <p>Denver,80216 CO</p>
                </Item>
                <Item url={img3}>
                    <div className="text-2xl font-bold mb-4">Wellcome</div>
                    <p>Friday</p>
                    <p>November 15, 2021</p>
                    <p>Dinner 7:00 pm</p>
                    <p className="mt-4">WELL COTTAGE</p>
                    <p>4416 Leo Street,</p>
                    <p>Denver,80216 CO</p>
                </Item>
            </div>

        </div>
    )
}

export default EventParty
