// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {
    getFirestore,
    addDoc,
    collection,
    onSnapshot,
    serverTimestamp,
    query,
    orderBy,
    deleteDoc,
    doc
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBcSm-h31u_Vxla_qn-_Ome5wC1sJsu8mo",
    authDomain: "tungvale-553cb.firebaseapp.com",
    projectId: "tungvale-553cb",
    storageBucket: "tungvale-553cb.appspot.com",
    messagingSenderId: "280274975461",
    appId: "1:280274975461:web:ff5002e2174f0acdfe720c",
    measurementId: "G-ZDB1SF77GN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addRegister = async (data) => {
    const docRef = await addDoc(collection(db, "Register"), {...data, time: serverTimestamp()});
    return docRef.id
}
export const deleteRegister = async (id) => {
    await deleteDoc(doc(db, "Register", id))
}
export const listenRegister = (callBack) => {
    onSnapshot(query(collection(db, "Register"), orderBy("time", "desc")), (querySnapshot) => {

        const list = [];
        querySnapshot.forEach((doc) => {
            list.push({...doc.data(),id:doc.id});
        });
        callBack(list);
    })
}
