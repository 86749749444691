import {AnimatePresence, motion} from "framer-motion";
import {createPortal} from "react-dom";
import cancel_icon from "../../assets/icons/cancel.svg"
import React, {useEffect, useRef} from "react";
import HTMLFlipBook from "react-pageflip";
import bia from "../../assets/background/bia.png";
import photo1 from "../../assets/photo/photo1.jpg";
import photo2 from "../../assets/photo/photo2.jpg";
import photo3 from "../../assets/photo/photo3.jpg";
import photo4 from "../../assets/photo/photo4.jpg";
import photo5 from "../../assets/photo/photo5.jpg";
import photo6 from "../../assets/photo/photo6.jpg";
import {useWindowSize} from "react-use";

const PageCover = React.forwardRef(({url}, ref) => {
    return (
        <div className="relative w-full h-full page page-cover  bg-white" ref={ref} data-density="hard">
            <div className="page-content w-full h-full rounded overflow-hidden">
                <img src={url} className="w-full h-full object-cover"/>
            </div>
            <div className="absolute w-full h-full top-0 left-0">
                <div className="page-cover-shadow absolute w-[1.2%] h-full left-0 top-0"/>
            </div>
        </div>
    );
});

const Page = React.forwardRef(({url}, ref) => {
    return (
        <div className="w-full h-full page p-4 bg-white" ref={ref}>
            <div className="page-content w-full h-full rounded overflow-hidden ">
                <img src={url} className="w-full h-full object-cover"/>
            </div>
        </div>
    );
});
const Content = () => {
    const [isRead, setRead] = React.useState(false);
    const [isPortrait, setPortrait] = React.useState(false);
    const {width, height} = useWindowSize()
    const handleOnFlip = ({data}) => {
        setRead(data > 0)
    }
    const onChangeOrientation = ({data}) => {
        setPortrait(data==="portrait")
    }
    const onInit =({data})=>{
        setPortrait(data.mode==="portrait")
    }
    return (
        <div className="w-full h-full p-8 flex items-center justify-center flex-col">
            <motion.div className="w-full h-[600px] flex items-center justify-center" animate={{x: (isRead||isPortrait) ? "0%" : "-25%"}}>
                <HTMLFlipBook width={550}
                              height={550}
                              size="stretch"
                              minWidth={360}
                              // maxWidth={1000}
                              onChangeOrientation={onChangeOrientation}
                              minHeight={360}
                              onFlip={handleOnFlip}
                              onInit={onInit}
                              maxShadowOpacity={0.5}
                              showCover={true}
                              mobileScrollSupport={true}
                >
                    <PageCover url={bia}></PageCover>
                    <Page url={photo1}/>
                    <Page url={photo2}/>
                    <Page url={photo3}/>
                    <Page url={photo4}/>
                    <Page url={photo5}/>
                    <Page url={photo6}/>
                </HTMLFlipBook>
            </motion.div>
            {/*<div className="h-20 w-full"></div>*/}
        </div>
    )
}
const DialogAlbum = ({open, onClose}) => {
    useEffect(() => {
        if (open) {
            document.body.style.paddingRight = "19px";
            document.documentElement.style.overflow = "hidden"
        } else {
            document.body.style.paddingRight = "0";
            document.documentElement.style.overflow = "auto"
        }
    }, [open])
    return (
        createPortal(<AnimatePresence>
            {open && (
                <motion.div
                    className="fixed top-0 left-0 w-screen h-screen z-[1000] bg-red-900"
                    initial={{opacity: 0, scale: 0, borderRadius: "100%"}}
                    animate={{opacity: 1, scale: 1, borderRadius: 0}}
                    exit={{opacity: 0, scale: 0}}
                    transition={{duration: 0.5}}
                >
                    <div className="w-full h-full">
                        <Content/>
                    </div>
                    <button onClick={onClose} className="fixed right-2 top-2">
                        <img src={cancel_icon} className="w-8 h-8"/>
                    </button>
                </motion.div>
            )}
        </AnimatePresence>, document.body)
    )
}
export default DialogAlbum
