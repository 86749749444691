import React from 'react';
import flower7 from "../../assets/background/flower7.svg"
import flower8 from "../../assets/background/flower8.svg"
import bia from "../../assets/background/bia.png"
import AnimatedText from "../AnimatedText";
import DialogAlbum from "../DialogAlbum";
import {useToggle} from "react-use";
import {motion} from "framer-motion";


const PhotoAlbum = ({}) => {
    const [open, toggle] = useToggle(false)
    return (
        <div className="py-36 relative w-full flex flex-col items-center">
            <img className="absolute w-[28%] bottom-0 left-0 opacity-50" src={flower7} alt=""/>
            <img className="absolute w-[47%] right-[-10%] top-[-1%] opacity-50 rotate-[-4deg]" src={flower8} alt=""/>
            <AnimatedText value="Photo Album"
                          className="text-2xl min-[767px]:text-5xl min-[400px]:text-4xl font-light"/>
            <div className="mb-16 mt-2 text-center px-2">Kéo chuột hoặc vuốt để xem toàn bộ album ảnh</div>
            <div className="w-full flex items-center justify-center">
                <motion.button
                    initial={{y: 200, opacity: 0}}
                    whileInView={{y: 0, opacity: 1}}
                    viewport={{once: true}}
                    transition={{duration: 1, type: "spring"}}
                    onClick={toggle}
                    className="relative min-[991px]:w-[30vw] min-[991px]:h-[30vw] w-[60vw] h-[60vw] page page-cover  bg-white">
                    <div className="page-content w-full h-full rounded overflow-hidden">
                        <img src={bia} className="w-full h-full object-cover"/>
                    </div>
                    <div className="absolute w-full h-full top-0 left-0">
                        <div className="page-cover-shadow absolute w-[1.2%] h-full left-0 top-0"/>
                    </div>
                </motion.button>
            </div>
            <DialogAlbum open={open} onClose={toggle}/>
        </div>
    )
}

export default PhotoAlbum
