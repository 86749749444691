import React, {useRef} from 'react';
import flower from "../../assets/background/flower6.svg"
import {motion} from 'framer-motion';
import AnimatedText from "../AnimatedText";

const PerfectDay = () => {
    const ref = useRef(null);
    return (
        <>
            <div ref={ref} className="min-[991px]:h-screen h-[400px] flex w-full relative z-10 items-center justify-center">
                <div className="italic min-[991px]:text-[3.3vw] text-[5vw] relative z-20">Hướng tới một ngày <span
                    className="font-bold text-[#a97143]">hoàn hảo</span>
                </div>
            </div>
            <div className="h-screen z-0 fixed flex items-center justify-center top-0 left-0 w-screen">
                <div  className="text-[#4b3f3e] text-[8vw] font-bold absolute right-0 opacity-30 pr-16">
                    <AnimatedText
                        x={0}
                        root={ref}
                        y={-100}
                        isDelay
                        once={false}
                        value="10/11/2024"
                        className="text-[#4b3f3e] text-[8vw] font-bold ">
                    </AnimatedText>
                </div>

                <img src={flower} className="w-[68vh] opacity-15 bottom-0 left-0 absolute"/>
            </div>
        </>

    )
}

export default PerfectDay
