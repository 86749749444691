import React from 'react';
import flower from "../../assets/background/flower9.svg"
import AnimatedText from "../AnimatedText";
import {motion} from 'framer-motion';


const Footer = ({}) => {
    return (
        <div className="w-full bg-[#f1c6b5] relative z-10 flex justify-center items-center flex-col py-40 mt-40">
            <img src={flower} className="opacity-30 absolute w-full bottom-0 left-0 bg-top"/>
            <AnimatedText value="Tùng & Lệ"
                          className="text-3xl min-[767px]:text-4xl min-[991px]:text-5xl font-light"/>
            <motion.div
                whileInView={{opacity: 1, y: 0}}
                initial={{opacity: 0, y: 100}}
                className="mt-2">
                Ngày 10 tháng 11 năm 2024
            </motion.div>
            <motion.div
                whileInView={{opacity: 1, y: 0}}
                initial={{opacity: 0, y: 100}}
                className="flex max-[780px]:flex-col mt-10">
                <div className="flex flex-col items-center mt-10">
                    <div className="text-xl mb-2 font-medium">Thanh Tùng</div>
                    <p>Thị trấn Hà Trung</p>
                    <p>Thanh Hoá</p>
                </div>
                <div className="w-40 max-[780px]:hidden"/>
                <div className="flex flex-col items-center  mt-10">
                    <div className="text-xl mb-2 font-medium">Nguyễn Lệ</div>
                    <p>Thành phố Thanh Hoá</p>
                    <p>Thanh Hoá</p>
                </div>
            </motion.div>
        </div>
    )
}

export default Footer
