import InvitationCard from "./components/InvitationCard";
import Frame from "./components/Frame";
import {GroomAndBride} from "./components/GroomAndBride";
import SpecialMoments from "./components/SpecialMoments";
import LoveLine from "./components/LoveLine";
import {useAnimate} from "framer-motion";
import PerfectDay from "./components/PerfectDay";
import PhotoAlbum from "./components/PhotoAlbum";
import EventParty from "./components/EventParty";
import Footer from "./components/Footer";
import {useSearchParams} from "react-router-dom";

function App() {
    const [scope, animate] = useAnimate()
    const [scope2, animate2] = useAnimate();

    const changeBackground =(isView)=>{
        if (isView) {
            animate(scope.current, {backgroundColor: "rgb(223, 237, 237)"}, {duration: 1, ease: 'easeInOut'})
            animate2(scope2.current, {backgroundColor: "rgb(223, 237, 237)"}, {duration: 1, ease: 'easeInOut'})
        } else {
            animate(scope.current, {backgroundColor: "rgb(251, 243, 240)"}, {duration: 1, ease: 'easeInOut'})
            animate2(scope2.current, {backgroundColor: "rgb(251, 243, 240)"}, {duration: 1, ease: 'easeInOut'})
        }
    }
    return (
        <div ref={scope2}>
            <Frame/>
            <div ref={scope} className="relative z-10">
                <InvitationCard/>
                <GroomAndBride/>
                <SpecialMoments/>
                <LoveLine changeBackground={changeBackground}/>
            </div>
            <PerfectDay/>
            <div style={{backgroundColor:"rgb(251, 243, 240)"}} className="relative overflow-hidden">
                <PhotoAlbum/>
                <EventParty/>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
