import AnimatedText from "../AnimatedText";
import love2 from "../../assets/loves/love2.jpeg"
import {motion} from 'framer-motion';

const Item = ({label, name, children}) => {
    return (
        <div className="flex flex-col items-center mt-8">
            <motion.div whileInView={{opacity: 1, x: 0}}
                        initial={{opacity: 0, x: -20}}
                        transition={{duration: 3, type: "spring"}}
                        viewport={{once: true}}
                        className="text-[#f1c6b5] mb-3 text-lg font-medium">{label}</motion.div>
            <motion.div whileInView={{opacity: 1, y: 0}}
                        initial={{opacity: 0, y: 20}}
                        transition={{duration: 3, type: "spring"}}
                        viewport={{once: true}}
                        className="font-bold text-2xl mb-2 min-[767px]:text-4xl min-[400px]:text-3xl">{name}</motion.div>
            <motion.div whileInView={{opacity: 1, y: 0}}
                        initial={{opacity: 0, y: 20}}
                        transition={{duration: 3, type: "spring"}}
                        viewport={{once: true}}
                        className="text-lg mt-6 w-[313px] text-center">{children}</motion.div>
        </div>
    )
}
export const GroomAndBride = () => {
    return (
        <div className="pb-20 w-full pt-20">
            <div className="m-auto pb-20 flex flex-col items-center justify-center max-w-[850px] w-full">
                <AnimatedText value="Nhà trai & Nhà gái" className="text-3xl min-[767px]:text-5xl min-[400px]:text-4xl font-light"/>
                <div className="flex mt-6 w-full justify-between flex-col min-[991px]:flex-row">
                    <Item name="Thanh Tùng" label="Nhà trai">
                        <p>Ông <strong className="font-semibold">Tống Văn Minh</strong></p>
                        <p>Bà <strong className="font-semibold">Nguyễn Thị Khuyên</strong></p>
                        <p>Thị trấn Hà Trung, Thanh Hoá</p>
                    </Item>
                    <Item name="Nguyễn Lệ" label="Nhà gái">
                        <p>Ông <strong className="font-semibold">Nguyền Đình Tinh</strong></p>
                        <p>Bà <strong className="font-semibold">Trịnh Thị Thuỷ</strong></p>
                        <p>Tp Thanh Hoá, Thanh Hoá</p>
                    </Item>
                </div>
            </div>
            <div className="min-[480px]:h-[90vh] h-[300px]"
                 style={{
                     backgroundImage: "linear-gradient(rgba(141, 143, 141, .48), rgba(141, 143, 141, .48)), url(" + love2 + ")",
                     backgroundPosition: "0 0, 30%", backgroundSize: "auto, cover",
                     backgroundAttachment: "scroll, fixed",
                     position: "relative"
                 }}>

            </div>

        </div>
    )
}
