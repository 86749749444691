import React from 'react';
import DialogRegister from "../DialogRegister";
import {useToggle} from "react-use";

const Frame = () => {
    const  [open,toggle] = useToggle(false);
    return (
        <>
            <button onClick={toggle}
                    className="z-50 flex text-xl backdrop-blur-sm uppercase text-[#a97143] justify-center items-center fixed border border-transparent  top-0 left-0 right-0 bottom-auto h-[77px] max-[780px]:h-[60px] border-b-[#fff]">
                Đăng ký tham gia
            </button>
            <div
                className="z-50 fixed border border-transparent max-[780px]:hidden top-0 left-0 right-auto bottom-0 w-[77px] border-r-[#fff]">
            </div>
            <div
                className="z-50 fixed border border-transparent max-[780px]:hidden top-0 left-auto right-0 bottom-0 w-[77px] border-l-[#fff]"></div>
            {/*<div className="z-50 fixed border border-[#000] pr-[30px] top-auto left-0 right-0 bottom-0 h-[77px] border-t-[#fff]"></div>*/}

            <DialogRegister open={open} onClose={toggle}/>
        </>
    )
}

export default Frame