import {AnimatePresence, motion} from "framer-motion";
import {createPortal} from "react-dom";
import cancel_icon from "../../assets/icons/cancel.svg"
import video_demo from "../../assets/videos/mov_bbb.mp4"
import {useEffect, useRef} from "react";

const Content = ({link}) => {
    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef.current) {
            setTimeout(() => {
                videoRef.current.play();
            }, 1000)
        }
    }, [videoRef.current])
    return (
        <div className="w-full h-full">
            <video ref={videoRef} width="100%" className="w-full h-full" controls>
                <source src={link} type="video/mp4"/>
            </video>
        </div>
    )
}
const DialogVideo = ({open, onClose ,link}) => {
    useEffect(() => {
        if (open) {
            document.body.style.paddingRight = "19px";
            document.documentElement.style.overflow ="hidden"
        }else {
            document.body.style.paddingRight = "0";
            document.documentElement.style.overflow ="auto"
        }
    }, [open])
    return (
        createPortal(<AnimatePresence>
            {open && (
                <motion.div
                    className="fixed top-0 left-0 w-screen h-screen z-[1000] bg-black "
                    initial={{opacity: 0, scale: 0, borderRadius: "100%"}}
                    animate={{opacity: 1, scale: 1, borderRadius: 0}}
                    exit={{opacity: 0, scale: 0,}}
                    transition={{duration: 0.5}}
                >
                    <div className="w-full h-full">
                        <Content link={link}/>
                    </div>
                    <button onClick={onClose} className="fixed right-2 top-2">
                        <img src={cancel_icon} className="w-8 h-8"/>
                    </button>
                </motion.div>
            )}
        </AnimatePresence>, document.body)
    )
}
export default DialogVideo
